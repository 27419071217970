import { render, staticRenderFns } from "./face_result_2.vue?vue&type=template&id=ec661ecc&scoped=true"
import script from "./face_result_2.vue?vue&type=script&lang=js"
export * from "./face_result_2.vue?vue&type=script&lang=js"
import style0 from "./face_result_2.vue?vue&type=style&index=0&id=ec661ecc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec661ecc",
  null
  
)

export default component.exports