<template>
   <div style="padding-left: 10px;">
      <div :id="echartId" :style="{width:width,height:height}"></div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import echarts from 'echarts';
   export default {
      zhName: '脉偏差分析图',
      name: 'echart_maitu',
      props: {
         name:{ },
         width:{
            default:"100%"
         },
         height:{
            default:"100px"
         },
         itemType:{

         },
         showYaxis:{
            default:false
         }

      },
      data() {
         return {
            echartId:Math.random().toString(36).slice(-10)
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            }
         })
      },
      watch: {
         itemType(n,o){
            this.handleData()
         }
      },
      mounted(){
         let n = this.$store.getters.reportData;
         if(!!n && this.itemType){
            this.handleData()
         }
      },
      methods: {

         handleData(){

            let seriesData = [];
            let data = this.$store.getters.reportData;
            let n = data.esbmAnalysisList.find(item=>item.itemType == this.itemType);

            if(n){
               seriesData = JSON.parse(n.optPulse1) ;
               this.initChart(seriesData);
            };

            // console.log(this.itemType)
         },

         initChart(seriesData) {

            let option = {
               title:{
                  text:this.name,
                  top:35,
                  left:-2 ,
                  textStyle:{
                     fontSize:12
                  },
                  show: this.name ? true :false
               },
               grid: {
                  top:8,
                  left: this.name ? 45 :-1,
                  right: 10,
                  bottom:5,
               },
            	xAxis: {
                  data: seriesData,
                  show:false,
               },
               yAxis: {
                  show:this.showYaxis ? true : false,
                  axisLine:{
                     lineStyle:{
                        type:"dashed"
                     }
                  }
               },
               series: [{
                  data: seriesData,
                  type: 'line',
                  symbol: "none",
                  smooth: true,
                  itemStyle: {
                     normal:{
                        lineStyle:{
                           width:1.5
                        }  
                     } 
                  }
               }]
            };
            var myChart = echarts.init(document.getElementById(this.echartId));
            myChart.setOption(option);

         }


      },
      created() {

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";


</style>
