<template>
	<div class="group-module">
		<div class="pad-12 " style="font-size:14px;padding-top:10px">
			<div class="flex-center">			
				<div style="position: relative">		
					<div class="yuan">	
						<div>
							<p><span>{{value}}</span> 分</p>
							<p style="color:#2D6FDF;font-size:12px">健康指数</p>	
						</div>				
					</div>		
					<svg :width="svgConfig.w" :height="svgConfig.h">
						<filter id="blurMe">
						  <feGaussianBlur in="SourceGraphic" stdDeviation="7" />
						</filter>	
						<path :d="pathD" stroke-width="1.5" stroke="#1989fa" stroke-linecap="round" :transform='"rotate("+ rotate +","+ cx +","+ cx + ")"' />					
						<circle :cx="cx" :cy="cy" :r="svgConfig.r" stroke-width="1" stroke="#1989fa" fill="none" stroke-dasharray="4"></circle>
						<circle :cx="cx" :cy="cy" :r="svgConfig.r" stroke-width="16" stroke="#1989fa" fill="none" class="dd" :stroke-dasharray="strokeDasharray" stroke-linecap="round" :transform='"rotate(-90,"+ cx +","+ cx + ")"'></circle>
						<circle :cx="cx" :cy="cy" :r="svgConfig.r" stroke-width="5" stroke="#eee" fill="none" :stroke-dasharray="strokeDasharray2" stroke-linecap="round" :transform='"rotate("+ rotate2 + ","+ cx +","+ cx + ")"'></circle>
						<circle :cx="cx" :cy="cy" :r="svgConfig.r*0.75" fill="rgba(54, 123, 241,0.3)" filter="url(#blurMe)"></circle>
						<circle :cx="cx" :cy="cy" :r="svgConfig.r*0.69"  fill="#ffffff"></circle>
					</svg>
				</div>
			</div>
			<div class="text-c"  style="margin-bottom:20px">
				<p style="height: 30px;font-size: 18px; font-weight: bold;">您当前健康状态很好</p> 
				<p style="color:#999">请关注疾病风险项目，多吃推荐食物</p>
			</div>
			<ul class="flex">
				<li class="flex-center">
					<div>
						<p><i class="icon-a-YogaRoom"></i>体质辨识</p>
						<p class="text-1">气虚质</p>
					</div>
				</li>
				<li class="flex-center">
					<div>
						<p><i class="icon-fei"></i>脏腑辨识</p>
						<p class="text-1">肾阴虚证</p>
					</div>
				</li>
				<li class="flex-center">
					<div>
						<p><i class="icon-fengxian"></i>疾病风险</p>
						<p class="text-1">8项</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
   import { mapState } from 'vuex';
   import columnTitle from '../components/columnTitle.vue';

   export default {
      name: 'analysisPulse',
		zhNmae:"疾病风险提示",
      components: {
         columnTitle,
      },
      props:{
         
      },
      data() {
         return {
            fromData:{
               tzresult:"",
               conclusion:""
            },
				svgConfig:{
					w:220,
					h:220,
					r:85,
				},
				value:80
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            tzresult: state => {
               return state.useReportStore.tzresult
            },
            reportType: state => {
               return state.useReportStore.reportType
            },
				
				cx(){
					return this.svgConfig.w/2
				},
				cy(){
					return this.svgConfig.h/2
				},
				strokeDasharray(){
					let _h = 2 * Math.PI.toFixed(2) * this.svgConfig.r;
					return `${_h*(this.value/100)} , ${_h}`
				},
				strokeDasharray2(){
					let _h = 2 * Math.PI.toFixed(2) * this.svgConfig.r;
					return `1 , ${_h}`
				},
				pathD(){
					let x = this.svgConfig.w/2
					let y = this.svgConfig.h/2 
					return `M ${x} ${y} L ${x} 36`
				},
				rotate(){
					return `${360*(this.value/100)}`
				},
				rotate2(){
					return `${360*(this.value/100)-90}`
				}
         }),
      },
      watch: {
         reportData(n,o){
            this.handleData()
         }
      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData();
         };
      },
      created() {

      },
      methods: {

         handleData(){
            this.fromData.tzresult = this.$store.getters.tzresult;
            this.fromData.conclusion = this.$store.getters.conclusion;
         }



      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";

	.yuan{
		position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);;z-index:2;
		display:flex;align-items: center;justify-content: center;text-align: center;
		span{font-size:26px;font-weight: bold;}
	}
	.flex > li{margin:0 8px;background: #F6F8FF;border-radius: 6px;flex:1;min-height:60px;text-align:center;line-height:20px;font-size:12px;color:#818181}
	.flex > li i{font-size:14px;color:#7FB1EC;margin-right:3px}
	.flex > li .text-1{color:#333;font-size:14px;}
	
</style>
