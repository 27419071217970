import { render, staticRenderFns } from "./echart_wutai.vue?vue&type=template&id=7bf2a453&scoped=true"
import script from "./echart_wutai.vue?vue&type=script&lang=js"
export * from "./echart_wutai.vue?vue&type=script&lang=js"
import style0 from "./echart_wutai.vue?vue&type=style&index=0&id=7bf2a453&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bf2a453",
  null
  
)

export default component.exports