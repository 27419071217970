<template>
	<div>
			
		<div class="group-module  pad-15">
			<column-nav icon="icon-mianfeichayin">茶饮</column-nav>
			<div class="flex-fanan">
				<div class="left-img">
					<img :src="constitutionPlan.teaMasterImg || 'images/no_img.png' "  @click="showImg(constitutionPlan.teaMasterImg)"/>
				</div>
				<div class="right-info">
					<div>
						<ul class="ul-img clear-both">
							<li v-for="(item,index) in constitutionPlan.teaAttaImg" :key="1">
							  <img :src="item" />
							</li>
						</ul>					
						<p class="img-name">{{ constitutionPlan.teaName || "--" }}</p>
						<p style="white-space: pre-wrap">
							{{ constitutionPlan.teaContent }}
						</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="group-module  pad-15">
			<column-nav icon="icon-mianfeichayin">推荐食谱</column-nav>
			<div class="flex-fanan">
				<div class="left-img">
				   <img :src="constitutionPlan.refoodMasterImg || 'images/no_img.png' "  @click="showImg(constitutionPlan.refoodMasterImg)"/>
				</div>
				<div class="right-info"  style="align-items: flex-start;padding-top: 10px;">
					<div>
						<ul class="ul-img clear-both">
							<li v-for="(item,index) in constitutionPlan.refoodAttaImg" :key="1">
							  <img :src="item" />
							</li>
						</ul>					
						<p class="img-name">{{ constitutionPlan.refoodName || "--" }}</p>
						<p style="white-space: pre-wrap;">
							{{ constitutionPlan.refoodContent }}
						</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="group-module  pad-15">
			<column-nav icon="icon-zhongchengyaofei">推荐膏方</column-nav>
			<div class="flex-fanan">
				<div class="left-img">
					<img :src="constitutionPlan.creamMasterImg || 'images/no_img.png' "  @click="showImg(constitutionPlan.creamMasterImg)"/>
				</div>
				<div class="right-info">
					<div>
						<p class="img-name">{{ constitutionPlan.creaName || "--" }}</p>
						<p style="white-space: pre-wrap;">
							{{ constitutionPlan.creamContent }}
						</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="group-module  pad-15">
			<column-nav icon="icon-menzhenxiyao">推荐中成药</column-nav>
			<div class="flex-fanan">
				<div class="left-img">
					<img :src="constitutionPlan.teaMasterImg || 'images/no_img.png' "  @click="showImg(constitutionPlan.teaMasterImg)"/>
				</div>
				<div class="right-info">
					<div>
						<p class="img-name">{{ constitutionPlan.teaName || "--" }}</p>
						<p style="white-space: pre-wrap;">
							{{ constitutionPlan.teaContent }}
						</p>
					</div>
				</div>
			</div>
		</div>
		
		<div class="group-module  pad-15">
			<column-nav icon="icon-kexueshiliao">推荐食疗</column-nav>
			<div class="flex-fanan border-s" >
				<div class="sup-text" style="color: #377600;background: #B9EA8E;">宜</div>
				<div class="left-img no-p">
					<img :src="constitutionPlan.foodUsefulMasterImg || 'images/no_img.png' " class="no-p"/>
				</div>
				<div class="right-info">
					<p  style="white-space: pre-wrap;font-size: 12px;">{{ constitutionPlan.foodUseful }}</p>
				</div>
			</div>
			<div class="flex-fanan border-s" >
				<div class="sup-text" style="color: #B30000;background: #FF6D6D;">忌</div>
				<div class="left-img ">
					<img :src="constitutionPlan.foodAvoidAttaImg || 'images/no_img.png' " class="no-p"/>
				</div>
				<div class="right-info">
					<p style="white-space: pre-wrap;font-size: 12px;">{{ constitutionPlan.foodAvoid }}</p>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
   import { mapState } from 'vuex';
	import { ImagePreview } from 'vant';
	import columnNav from '../components/columnNav.vue';
   export default {
      zhName:'面诊结果、脉诊结果',
      name: 'analysisTongue',
      components: {
         columnNav
      },
      props:{

      },
      data() {
         return {
            constitutionPlan:{
               teaAttaImg:[]
            },
            visceraPlan:{

            }
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
            let constitutionPlan = data.constitutionPlan;
            let visceraPlan  =  data.visceraPlan;           
				
				if(constitutionPlan.teaAttaImg){
				   try {
				      constitutionPlan.teaAttaImg = JSON.parse(constitutionPlan.teaAttaImg)
				   } catch (error) {
				
				   }
				}
				if(constitutionPlan.refoodAttaImg){
				   try {
				      constitutionPlan.refoodAttaImg = JSON.parse(constitutionPlan.refoodAttaImg)
				   } catch (error) {
				
				   }
				}
				this.constitutionPlan = {...constitutionPlan};
            this.visceraPlan = {...visceraPlan};
         },
			
			showImg(img){
				if(img){
					ImagePreview([img]);
				}				
			}

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
   .div-flex{display: flex;}
   .div-flex > div{flex:1;}
   .sup-text{
      position: absolute;width:20px;height: 20px;line-height: 20px;text-align: center;border-radius:6px 0 6px 0;top:0px;left:0px;text-shadow: 1px 1px 0 rgba(255,255,255,0.5);font-size: 12px;
   }
	.border-s{border: solid 1px #aaa;border-radius: 6px;margin:15px 0;position: relative;padding:12px 9px;}
</style>
