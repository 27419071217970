<template>
   <div id="report" @scroll="handleScroll">
		<div class="flex user-info">
			<div class="info-1 flex-center">
				<img src="/images/doctor_header.png" >
			</div>
			<div class="info-2">
				<p>
					<span style="color:#333333;font-size:13px;">{{ detailData.realName }}</span>
					<span>{{ detailData.sex ? '女':"男" }}</span>
					<span>{{ detailData.age }} 岁</span>
				</p>
			</div>
			<div class="info-3" @click="gotoReportPdf">
				原始报告<i class="icon-gengdu-copy"></i>
			</div>			
		</div>
		<div style="height:40px;"></div>
		
		<page-one>健康状态</page-one>
		<page-tzjg id="tzbs" ref="tzbs">体质辨识</page-tzjg>
		<page-jbfx id="jbfx" ref="jbfx">疾病风险提示</page-jbfx>
		<page-zfbzjg id="zfbs" ref="zfbs">脏腑辨证结果</page-zfbzjg>
		
		<page-szcs id="szcs" ref="szcs">
			<page-szjg v-if="reportType.isTongue">舌诊结果</page-szjg>
			<page-mzjg v-if="reportType.isFace">面诊结果</page-mzjg>
			<page-mai v-if="reportType.isPulse">脉诊结果</page-mai>
		</page-szcs>
		
		<page-ystj>			
			<page-four>茶饮、推荐食谱、推荐膏方</page-four>			
		</page-ystj>
		
		<page-ysfa id="ysfa" ref="ysfa">
			<page-tjfj>推荐方剂</page-tjfj>
			<page-llfa>推荐理疗方案</page-llfa>
			<page-eight>推荐季节调养、推荐音乐治疗、起居调摄</page-eight>
			<page-ydty>运动调养</page-ydty>
		</page-ysfa>
		
		<div style="height:90px;"></div>
		
		<div class="zc-nav">
			<ul v-for="(item,i) in navList" :class="currentId ==item.id ? 'current':''  " @click="gotoId(item.id)">
				<li>
					<span class="flex-center"><i :class="item.icon"></i></span>
				</li>
				<li>{{item.name}}</li>
			</ul>
		</div>
		
   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import pageTzjg from './module/page_tzjg.vue';
	import pageJbfx from './module/page_jbfx.vue';
	import pageSzcs from './module/page_szcs.vue';
	import pageSzjg from './module/page_szjg.vue';
	import pageMzjg from './module/page_mzjg.vue';		
	import pageMai from './module/page_mai';
   import pageZfbzjg from './module/page_zfbzjg.vue';
	import pageYstj from './module/page_ystj';
	import pageYsfa from './module/page_ysfa';
   import pageTjfj from './module/page_tjfj';
   import pageLlfa from './module/page_llfa.vue';
   import pageYdty from './module/page_ydty.vue';		
   import pageFour from './module/page_4.vue';
	import pageOne from './module/page_1.vue';
   import pageEight from './module/page_8.vue';


   export default {
      name: 'report',
      components: {
			pageOne,
         pageTzjg,
			pageJbfx,
			pageSzcs,
			pageSzjg,	
			pageMzjg,
         pageZfbzjg,			
         pageMai,
			pageYstj,
			pageYsfa,
			pageTjfj,			
         pageFour,
         pageLlfa,
         pageYdty,
         pageEight,
      },
      data() {
         return {
            id:""  ,   // 1218209362602885120  1266066113238663168  1268223347737493504  1257300540996452352
            version:"1.0",
            detailData:{
               createTime:"13123",
					pdfUrl:"",
            },
				navList:[
					{ name:"体质辨识", id:"tzbs", icon:"icon-a-YogaRoom" },
					{ name:"疾病风险", id:"jbfx", icon:"icon-fengxian" },
					{ name:"脏腑辩证", id:"zfbs", icon:"icon-fei"},
					{ name:"四诊参数", id:"szcs", icon:"icon-zhexiantu" },
					{ name:"养生方案", id:"ysfa", icon:"icon-caidanliebiao" }
				],
				currentId:"tzbs"
         }
      },
      computed:{
         ...mapState({
            reportType: state => {
               return state.useReportStore.reportType
            },
            isEnd: state => {
               return state.useReportStore.isEnd
            }
         })
      },
      created() {
         let { id, version } = this.$route.query
         if(id){
            this.id = id;
         }
         if(version){
            this.version = version
         }
         this.$store.commit('setVersion', this.version)
      },
      mounted() {
         if(this.id){
            this.ajax_getReportDetail()
         }
			
      },
      methods: {
			
			gotoId(id){
				this.currentId = id;
				let _route = this.$route;				
				let url = `${location.origin}${_route.path}?id=${_route.query.id}#${id}`
				location.href = url;
			},

         ajax_getReportDetail(){
            this.$store.dispatch('handleGetReportDetail',{id:this.id}).then((res)=>{
               this.detailData = res.result;
            })
         },

         handleScroll(event){				
				this.navList.forEach(item=>{
					let element = document.getElementById(item.id);
					let top = element.getBoundingClientRect().top;
					if(top<50 && top>-150){
						this.currentId = item.id
					}
				});
			},
			
			gotoReportPdf(){
				this.$router.push({
					path:"/reportPdf",
					query:{
						pdfUrl:this.detailData.pdfUrl || "",
					}
				})
			}

      }
   }
</script>

<style lang="less" scoped>
   @import "./report.less";
   .zc-nav{
		position: fixed;bottom: 12px;left:12px;right: 12px;border: solid 1px #ddd;border-radius: 12px;background: #fff;box-shadow: 0 0 16px rgba(0,0,0,0.2);
		height: 66px; display: flex;padding-top:8px;z-index:10;
	}
	.zc-nav ul{flex:1;text-align:center;font-size: 12px;color: #717171;}
	.zc-nav ul span{width: 27px;height: 27px;background: #E7E7E7;border-radius:8px;margin: 0 auto;}
	.zc-nav ul span i{color:#aaa;font-size: 16px;}
	.zc-nav ul li:last-child{margin-top:5px;}
	.zc-nav ul.current{color: #2D6FDF;}
	.zc-nav ul.current span{background: #2D6FDF;}
	.zc-nav ul.current i{color: #fff;}
	
	.user-info{
		position: fixed;background: #fff;height:40px;line-height:40px;left: 0;right: 0;top: 0;	z-index:20;box-shadow:0 0 6px rgba(0,0,0,0.25);color:#5C5C5C;
		.info-1{
			width:50px;
			img{border-radius:50%;width:28px;height:28px}
		}
		.info-2{
			flex:1;display:flex;align-items: center;font-size:12px;
			span{margin-right:10px}
		}
		.info-3{margin-left:auto;padding-right:10px;font-size:13px;}
	}
	.icon-gengdu-copy{font-size:14px;margin-left:0px;}
	
</style>
