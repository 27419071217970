<template>
   <div class="column-nav">
		<div class="font-icon"> 
			<i class="icon-youjiantou-yuansanjiaoxing"></i>
		</div>
		<div style="flex: 1;color:#484950 ;">
			<slot></slot>
		</div>
   </div>
</template>

<script>

   export default {
      props:{
         
      }
   }
</script>

<style lang="less" scoped>
   .column-nav{
      display: flex;height: 32px;color:#186FFD;line-height:20px;
      .font-icon i{margin-right: 2px;font-size: 14px;}
   }

</style>
