<template>
   <table width="100%" class="report-table theme-1 td-p-0" border="0"  style="font-size: 12px;">
      <!-- <tr>
         <td width="20%" class="table-label2"></td>
         <td width="30%" class="table-label2">结果</td>
         <td class="table-label2">说明</td>
      </tr> -->
      <tr>
         <td>舌色</td>
         <td>{{ fromData.shese }}</td>
         <td>{{ fromData.sheseMsResult }}</td>
      </tr>
      <tr>
         <td>舌形-胖瘦</td>
         <td>{{ fromData.pangshouMs }}</td>
         <td>{{ fromData.pangshouMsResult }}</td>
      </tr>
      <tr>
         <td>苔色-老嫩</td>
         <td>{{ fromData.laonenMs }}</td>
         <td>{{ fromData.laonenMsResult }}</td>
      </tr>
      <tr>
         <td>舌苔-厚薄</td>
         <td>{{ fromData.houbaoMs }}</td>
         <td>{{ fromData.houbaoMsResult }}</td>
      </tr>
      <tr>
         <td>舌苔-腐腻</td>
         <td>{{ fromData.funiMs }}</td>
         <td>{{ fromData.funiMsResult }}</td>
      </tr>
   </table>
</template>

<script>
   import { mapState } from 'vuex';
   export default {
      zhName:'舌象测定结果',
      name: 'result_tongue',
      props:{},
      data() {
         return {
            fromData:{
               shese:" ",         // 舌色
               pangshouMs:" ",    // 胖瘦
               laonenMs:" ",      // 老嫩
               qttz:" ",          // 裂纹
               houbaoMs:" ",      // 厚薄
               funiMs:" ",        // 腐腻
               taiseMs:" ",       // 苔色
               result:" ",        // 整体舌象结果
               shezhuang:"",
            }
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData;
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
            let tongueAnalysisVo = data.tongueAnalysisVo;
            this.fromData = tongueAnalysisVo;
            // for(let k in this.fromData){
            //    this.fromData[k] = tongueAnalysisVo[k];
            // };
         }

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
   .table-label{background:#186FFD;color: #fff;}
</style>
