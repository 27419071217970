<template>
   <table width="100%" class="report-table theme-1 td-p-5" border="0" style="font-size: 12px;">
     <!-- <tr style="height: 30px">
         <td width="60" class="table-label"></td>
         <td width="100" class="table-label">结果</td>
         <td class="table-label">说明</td>
      </tr> -->
      <tr>
         <td>面色</td>
         <td>{{ fromData.faceColor }}</td>
         <td>{{ fromData.faceColorResult }}</td>
      </tr>
      <tr>
         <td>唇色</td>
         <td>{{ fromData.lipColor }}</td>
         <td>{{ fromData.lipColorResult }}</td>
      </tr>
      <tr>
         <td>光泽</td>
         <td>{{ fromData.faceGloss }}</td>
         <td>{{ fromData.faceGlossResult }}</td>
      </tr>
   </table>
</template>

<script>
   import { mapState } from 'vuex';
   export default {
      zhName:'面诊测定结果',
      name: 'result_face',
      props:{
			
      },
      data() {
         return {
            fromData:{
               faceColor:"",     // 面色
               faceGloss:"",     // 光泽
               lipColor:"",      // 唇色
               result:""         // 整体面诊结果
            }
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
            let faceAnalysisVo = data.faceAnalysisVo;
            this.fromData = {...faceAnalysisVo}
            // for(let k in this.fromData){
            //    this.fromData[k] = faceAnalysisVo[k];
            // };
         }

      },
      created() {

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
   .table-label{background:#186FFD;color: #fff;}
</style>
