<template>
	<div  class="group-module  pad-15" v-if="isMeridianPlan">
		<column-nav icon="icon-tuinake">推荐理疗方案</column-nav>
		<div style="padding: 0 8px;">
			<table width="100%" class="table-llfa" border="0" style="font-size: 12px;">
				<tr v-for="(item,i) in fromData.arr" :key="i" >					
					<td  class="td-span" style="text-align: left;">
						<div>
							<span>穴位名称</span>：{{ item.acupoint }}
						</div>
						<div>
							<span>穴位位置：</span>{{ item.location }}
						</div>
						<div>
							<span>医疗方案：</span>
							{{ item.treatment }}
						</div>
						<div>
							<span>理疗方案</span>：
							{{ item.physiotherapy }}
						</div>
					</td>
					<td style="padding-left: 20px;">
						<img :src="item.imgUrl" style="width: 100px;"  @click="showImg(item.imgUrl)">
					</td>					
				</tr>
			</table>
		</div>
	</div>	
</template>

<script>
   import { mapState } from 'vuex';
	import { ImagePreview } from 'vant';
   import columnTitle from '../components/columnTitle.vue';
   import columnNav from '../components/columnNav.vue';

   export default {
      zhName:'推荐理疗方案',
      name: 'analysisTongue',
      components: {
         columnNav
      },
      props:{

      },
      data() {
         return {
            fromData:{
               arr:[],
            },
				isMeridianPlan:true
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            reportType: state => {
               return state.useReportStore.reportType
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
				if(!data.meridianPlan){
					this.isMeridianPlan = false;
					return 
				}
				
            let _fromData = data.meridianPlan;
               _fromData.arr = [];

            for(let i=0; i<20; i++){
               let _acupoint = 'acupoint' + i;
               let _img = 'img' + i +"Url";
               let _location = 'location' + i;
               let _physiotherapy = 'physiotherapy' + i;
               let _treatment = 'treatment' + i;

               if(_fromData[_acupoint]){
                  _fromData.arr.push({
                     acupoint:_fromData[_acupoint],
                     imgUrl:_fromData[_img] || "",
                     location:_fromData[_location] || "",
                     physiotherapy:_fromData[_physiotherapy] || "",
                     treatment:_fromData[_treatment] || ""
                  });
               };
            };


            this.fromData = _fromData;
            this.fromData.createTime = data.createTime;
         },
			
			showImg(img){
				if(img){
					ImagePreview([img]);
				}
			}

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
	.table-llfa{
		td{padding: 10px 0;}
		tr{border-bottom: solid 1px #ddd;}
	}
	.td-span div{padding: 3px 0;}
	.td-span span{color: #6C6C6C;}
</style>
