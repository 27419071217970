<template>
   <div class="report-page">
		<slot></slot>
   </div>
</template>

<script>

   export default {
      zhName:'report_content',
      name: 'report_content',
      components: {
      },
      props:{

      },
      data() {
         return {

         }
      },
      computed:{

      },
      watch: {

      },
      mounted() {

      },
      created() {

      },
      methods: {



      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
	
	.report-page{
		width:100%;
		border-radius: 0px;
		overflow: hidden;   
		display: flex;
		flex-direction: column;
	}

</style>
