<template>
   <div>
      <div :id="echartId" :style="{width:width,height:height}"></div>
   </div>
</template>

<script>
   import {
      mapState
   } from 'vuex';
   import echarts from 'echarts';
   export default {
      zhName: '五态',
      name: 'echart_wutai',
      props: {
         width: {
            default: "100%"
         },
         height: {
            default: "60px"
         },
         theme:{
            default:"1"
         }
      },
      data() {
         return {
            echartId: Math.random().toString(36).slice(-10),

         }
      },
      computed: {
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            }
         })
      },
      watch: {
         reportData(n, o) {
            this.handleData(n)
         }
      },
      methods: {

         handleData(data) {
            let wargData = data.constitutionResult.find(item => item.constitutionQuestionType == 3);
            let dataArray = [wargData.yangxuNum, wargData.qixuNum, wargData.pingheNum, wargData.tanshiNum, wargData.yinxuNum];

            let xAxisData = ["太阳", "少阳",  "阴阳平和", "少阴", "太阴",];
            	let colorArray = [];
            	const maxNum = Math.max(...dataArray)
            	const maxIndex = dataArray.indexOf(maxNum)
            	colorArray = dataArray.map(function(item, index) {
            		if (index == maxIndex) {
            			return '#E33A3D'
            		} else {
            			return '#00A197'
            		}
            	});

            	this.initChart(dataArray, xAxisData, colorArray)

         },

         initChart(dataArray, xAxisData, colorArray) {
            console.log(dataArray)

            var option = {
               grid: {
                  left: 50,
                  right: 15,
                  top: 30,
                  bottom: 30
               },
               xAxis: {
                  type: "category",
                  data: xAxisData,
                  axisLabel: {
                     color: "#333",
                  },
                  axisTick: {
                     show: false,
                  },
                  axisLine:{
                     show:this.theme==1 ? true : false,
                  }
               },
               yAxis: {
                  show: true,
                  type: "value",
                  max: 100,
                  min: 0,
                  interval: 20,
                  axisLabel: {
                     color: "#444",
                  },
                  nameLocation: "middle",
                  nameGap: 30,
                  axisLine:{
                     show:this.theme==1 ? true : false,
                  },
                  axisTick: {
                     show: false,
                  },
                  splitLine: {
                     show: false
                  },
               },

               series: [{
                  type: "bar",
                  data: dataArray,
                  itemStyle: {
                     normal: {
                        color: function(params) {
                           return colorArray[params.dataIndex]
                        },
                        barBorderRadius:this.theme==1 ? 0 :30
                     },
                  },
                  showBackground: false,
                  backgroundStyle: {
                     color: "#333",
                     borderRadius: 10
                  },
                  label: {
                     show: true,
                     position: 'top',
                     color: function(params) {
                        return colorArray[params.dataIndex]
                     },
                  },
                  barWidth: 15,
               }],
               backgroundColor: this.theme==1 ? "#eee" : ""
            };
            var myChart = echarts.init(document.getElementById(this.echartId));
            myChart.setOption(option);

         }


      },
      mounted() {

         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };

      },
      created() {

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";

</style>
