<template>         
	<div class="group-module" :id="id">
		<column-title  tostText="不同体质具有独特的健康特点和相应的调理方法。通过体质辨识，您可以更深入地了解自己的身体状况，从而采取合适的养生措施，预防疾病的发生。">
			体质辨识
			<template #tost>您可以更全面地了解自身的身体状况，从而采取更合适的养生措施</template>
			<template #right>查看说明</template>
		</column-title>
		<div class="pad-12">
			<div style="text-align: center;font-size: 18px;">
				<span v-html="fromData.tzresult"></span>
			</div>
			<div style="height: 260px;">
				<echart-wenzhen height="260px" v-if="reportType.isWenzhen" theme="2"></echart-wenzhen>
				<echart-wutai height="260px" v-if="reportType.isWutai" theme="2"></echart-wutai>
			</div>
			<div class="module-result">
				<!-- 你性格内向、情绪不稳定、胆小、不喜欢冒险。平时候不怎么想说话，一说话感觉气不够用，容易疲倦，没有什么精神。容易出现以下症状:<br> -->
				{{constitutionPlan.resultAnalysis}}
			</div>
			<div style="color: #8D9BB2;margin-top: 10px;padding: 0 10px 10px;font-size: 12px;">
				此次测量的体质是您的瞬间体质，如果想要更
			</div>
		</div>
		
	</div>
</template>

<script>
   import { mapState } from 'vuex';
   import columnTitle from '../components/columnTitle.vue';

   import echartWenzhen from '../echart_module/echart_wenzhen.vue';
   import echartWutai from '../echart_module/echart_wutai.vue';

   export default {
      name: 'tzjg',
      components: {
         columnTitle,echartWenzhen,echartWutai
      },
      props:{
         analysisType:{
            default:""
         },
			id:{
				
			}
      },
      data() {
         return {
            fromData:{
               tzresult:"",
               conclusion:""
            },
				constitutionPlan:{}
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            tzresult: state => {
               return state.useReportStore.tzresult
            },
            reportType: state => {
               return state.useReportStore.reportType
            },
         }),
      },
      watch: {
         reportData(n,o){
            this.handleData()
         }
      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData();
         };
      },
      created() {

      },
      methods: {

         handleData(){
            this.fromData.tzresult = this.$store.getters.tzresult;
            this.fromData.conclusion = this.$store.getters.conclusion;
				this.constitutionPlan =  this.$store.getters.reportData.constitutionPlan;
         }



      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";



</style>
