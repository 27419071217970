<template>
	<div class="group-module  pad-15">
		<column-nav :showIcon="false">
			舌诊结果
		</column-nav>
		<div>
			<div class="zk-flex column-module">
				<div class="left-img" style="width: 100px;">
					<img :src="fromData.analysisImage" @click="showImg(fromData.analysisImage)">
				</div>
				<div class="flex-1 font-12">
					<p>结论说明: {{ fromData.result }}</p>
				</div>
			</div>					
			<div>
				<tongue-result></tongue-result>
			</div>
		</div>
	</div>
</template>

<script>
   import { mapState } from 'vuex';
	import { ImagePreview } from 'vant';
   import columnTitle from '../components/columnTitle.vue';
	import columnNav from '../components/columnNav.vue';
   import tongueResult from '../table_module/tongue_result_2';

   export default {
      zhName:'舌诊结果',
      name: 'analysisTongue',
      components: {
         columnTitle,tongueResult,columnNav
      },
      props:{

      },
      data() {
         return {
            fromData:{
               // analysisImage:""
            }
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            reportType: state => {
               return state.useReportStore.reportType
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
            let tongueAnalysisVo = {...data.tongueAnalysisVo};
            this.fromData = tongueAnalysisVo;
         },
			
			showImg(img){
				if(img){
					ImagePreview([img]);
				}
			}
			
			
      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
</style>
