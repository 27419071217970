<template>
	<div class="group-module  pad-15">
		<column-nav> 面诊结果 </column-nav>
		<div>
			
			<div class="zk-flex column-module">
				<div class="left-img" style="width: 100px;">
					<img :src="fromData.analysisImage" @click="showImg(fromData.analysisImage)">
				</div>
				<div class="flex-1 font-12">
					结论说明: {{ fromData.resultInfo }}
				</div>
			</div>					
			<div>
				<face-result></face-result>
			</div>
			
		</div>
	</div>
</template>

<script>
   import { mapState } from 'vuex';
	import { ImagePreview } from 'vant';
	import columnNav from '../components/columnNav.vue';
   import faceResult from '../table_module/face_result_2';

   export default {
      zhName:'舌诊结果',
      name: 'analysisTongue',
      components: {
         faceResult,columnNav
      },
      props:{

      },
      data() {
         return {
            fromData:{
               analysisImage:"",
					lipColorResult:"",
            },
            faceAnalysisVo:{

            }
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            reportType: state => {
               return state.useReportStore.reportType
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
            let faceAnalysisVo = data.faceAnalysisVo || {};
            this.fromData = faceAnalysisVo;				
         },
			
			showImg(img){
				if(img){
					ImagePreview([img]);
				}
			}

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
</style>
