<template>
	<div class="group-module">
		<column-title tostText="">
			疾病风险
			<template #tost>你当前身体的疾病风险预估</template>
			<template #right>查看说明</template>
		</column-title>
		<div class="pad-12 " style="font-size:14px;min-height:200px;padding-left:18px">
			<ul class='flex fengxian-sup'>
				<li>
					<span class="span-g"></span> 高风险
				</li>
				<li>
					<span class="span-z"></span> 中风险
				</li>
				<li>
					<span class="span-d"></span> 低风险
				</li>
			</ul>
			<div style="padding:20px 0">
				<ul class='flex item-fx' v-for="(item,index) in fromData" :key="index">
					<li class="fx-name">{{item.name}}</li>
					<li class="fx-jdt">
						<p></p>
						<p :style='`background:${item.color};width:${item.value}%`'></p>
					</li>
					<li class="fx-val">{{item.value}}%</li>
					<li><i class="icon-gengdu-copy"></i></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import columnTitle from '../components/columnTitle.vue';

	export default {
		name: 'analysisPulse',
		zhNmae: "疾病风险提示",
		components: {
			columnTitle,
		},
		props: {

		},
		data() {
			return {
				fromData: [
					{ name:"高血压",  value:90, color:"#F01C20" },
					{ name:"围绝经期",value:84, color:"#F01C20" },
					{ name:"高脂血症",value:70, color:"#F79A1B" },
					{ name:"低血糖",  value:66, color:"#F79A1B" },
					{ name:"低血糖",  value:40, color:"#00A197" }
				],
			}
		},
		computed: {
			...mapState({
				reportData: state => {
					return state.useReportStore.reportData
				}
			}),
		},
		watch: {
			reportData(n, o) {
				this.handleData()
			}
		},
		mounted() {
			let n = this.$store.getters.reportData;
			if (!!n) {
				this.handleData();
			};
		},
		created() {

		},
		methods: {

			handleData() {
				
			}
			
		}
	}
</script>

<style lang="less" scoped>
	@import "../report.less";
	.fengxian-sup>li{margin-right:20px}
	.fengxian-sup>li span{
		display: inline-block; width: 10px;height: 10px;border-radius: 50%;
	}
	.fengxian-sup>li span.span-g{background:#F01C20}
	.fengxian-sup>li span.span-z{background:#F79A1B}
	.fengxian-sup>li span.span-d{background:#00A197}
	
	.item-fx{line-height:30px;color:#444444}
	
	.fx-name{width:66px;}
	.fx-jdt{flex:1;position: relative;}
	.fx-jdt p{position: absolute;height:10px;width:100%;top:11px;background:#eee;border-radius:0 8px 8px 0;}	
	.fx-val{margin-left:10px}
	.icon-gengdu-copy{font-size:14px;margin-left:5px}
	
</style>
