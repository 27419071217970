<template>
	<div  class="group-module  pad-15">
		<column-nav icon="icon-a-YogaRoom">运动调养</column-nav>
		<div>
			<div style="line-height: 26px;color: #484950;font-size: 13px;text-indent: 26px;padding: 0 8px;margin-bottom:12px;">
				{{ fromData.exerciseAdvice }}
			</div>
			<table width="100%" class="report-table theme-2" border="0">
				<template v-for="(item,i) in exercises">
					<tr>
						<td colspan="2"  class="table-label2">{{item.exercise}}</td>
					</tr>
					<tr>
						<td style="text-align: left;font-size: 12px;">
							<div v-for="(dz,index) in item.arr" :key="index" style="margin: 10px;">
								<p style="margin-right: 10px;color: #6C6C6C;height: 20px;">动作{{ num[index] }}:</p>
								<p style="flex: 1;">{{dz}}</p>
							</div>
						</td>
						<td width="30%">
							<img :src="item.imgUrl" style="width:60%;margin: 10px 0;"  @click="showImg(item.imgUrl)">
						</td>
					</tr>
				</template>
			</table>

		</div>
	</div>
</template>

<script>
   import { mapState } from 'vuex';
	import { ImagePreview } from 'vant';
   import columnNav from '../components/columnNav.vue';

   export default {
      zhName:'运动调养',
      name: 'analysisTongue',
      components: {
         columnNav
      },
      props:{

      },
      data() {
         return {
            fromData:{
               exerciseAdvice:"",
            },
            exercises:[],
            num:["一","二","三","四","五","六","七","八","九"]
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            reportType: state => {
               return state.useReportStore.reportType
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
            let _fromData = {
               ...data.constitutionPlan
            };
				let exercises = [];
				if(data.constitutionPlan){
					exercises = data.constitutionPlan.exercises.map(item=>{
					   item.arr = [];
					   for(let i=0;i<9;i++){
					      let _c = item['operate'+i];
					      if(_c){
					         item.arr.push(_c)
					      }
					   }
					   return item;
					});
				}
            
            console.log(exercises)
            this.fromData = _fromData;
            this.exercises = exercises;
         },
			
			showImg(img){
				if(img){
					ImagePreview([img]);
				}
			}

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
   .div-flex{display: flex;}
   .div-flex > div{flex:1;}
</style>
