<template>         
	<div class="group-module" :id="id">
		<column-title :showIcon="false">
			养生方案
			<template #tost>根据你当前的身体情况，推荐最适配你的饮食</template>
		</column-title>
		<div style="margin-bottom: 10px;">
			<slot></slot>			
		</div>
	</div>
</template>

<script>
   import { mapState } from 'vuex';
   import columnTitle from '../components/columnTitle.vue';

   export default {
      name: 'ystx',
		zhName:"养生方案",
      components: {
         columnTitle,
      },
		props:{
			id:{}
		},
      data() {
         return {
           
         }
      },
      computed:{
         
      },
      watch: {
         
      },
      mounted() {
         ;
      },
      created() {

      },
      methods: {



      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";



</style>
