<template>	
	<div class='group-module pad-15' v-if="isVisceraPlan">
		<column-nav icon="icon-zhongyaobao">推荐方剂</column-nav>				
		<table width="100%" class="report-table theme-2" border="0" style="font-size: 12px;">
		  <tr style="height: 30px">
				<td width="60" class="table-label2">方剂名称</td>
				<td width="100" class="table-label2">方剂组成</td>
			</tr>
			<tr v-for="(item,i) in fromData.arr" :key="i" >
				<td>{{ item.prescription }}</td>
				<td>{{ item.composition }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
   import { mapState } from 'vuex';
   import columnNav from '../components/columnNav.vue';

   export default {
      zhName:'推荐方剂',
      name: 'analysisTongue',
      components: {
         columnNav
      },
      data() {
         return {
            fromData:{
               arr:[],
            },
				isVisceraPlan:true
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
				if(!data.visceraPlan){
				   this.isVisceraPlan = false
				   return
				}
            let _fromData = {
               ...data.visceraPlan,
               arr:[]
            };
            for(let i=0; i<20; i++){
               let  _cf = 'prescription' + i;
               let  _yp = 'prescription' + i + "Composition";
               if(_fromData[_cf]){
                  _fromData.arr.push({
                     prescription:_fromData[_cf],
                     composition:_fromData[_yp] || "",
                  });
               };
            };
            console.log({..._fromData})
            this.fromData = _fromData;
         }

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
   .div-flex{display: flex;}
</style>
