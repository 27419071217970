<template>
   <div>
		<div class="group-module  pad-15">
			<column-nav icon="icon-fx">推荐季节调养</column-nav>
			<div class="" style="line-height: 26px;color: #484950;font-size: 13px;text-indent: 26px;padding: 0 8px;">
				{{ constitutionPlan.seasonAdvice }}
			</div>
		</div>

		<div class="group-module  pad-15">
			<column-nav icon="icon-yinle">推荐音乐治疗</column-nav>
			<div class="" style="min-height: 60px;font-size: 13px;">
				
				<div v-for="(item,index) in musicVo" style="margin: 10px 0 15px;line-height: 22px;display:flex;marg">
					<div style="flex:1">
						<p>音乐调养方案{{num[index]}} : {{ item.fangan || "--" }}</p>
						<p>聆听时间 : {{ item.ltsj || "--"  }}</p>
						<p>推荐曲目 : {{ item.gdtj || "--"  }}</p>
					</div>
					<div class='yy-img' v-if="index==0">
						<img src="imagesReport/yinyun.png" style="margin-bottom: 0;"  @click="showImg"/>
					</div>
				</div>
				
			</div>
		</div>

		<div class="group-module  pad-15">
			<column-nav icon="icon-qiju">起居调摄</column-nav>
			<div style="line-height: 26px;font-size: 13px;">
				{{ reportData.workAndRestAdvice }}
			</div>
		</div>
		
   </div>
</template>

<script>
   import { mapState } from 'vuex';
	import { ImagePreview } from 'vant';
   import columnNav from '../components/columnNav.vue';

   export default {
      zhName:'推荐季节调养、推荐音乐治疗、起居调摄',
      name: 'analysisTongue',
      components: {
         columnNav
      },
      props:{

      },
      data() {
         return {
            fromData:{
               seasonAdvice:"111",
               arr:[],
            },
            constitutionPlan:{ },
            workAndRestAdvice:"",
            musicVo:[],
            num:["一","二","三","四","五","六","七","八"]
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            reportType: state => {
               return state.useReportStore.reportType
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){

            let _constitutionPlan = {...data.constitutionPlan};
            
            this.constitutionPlan = _constitutionPlan;
            this.workAndRestAdvice = data.workAndRestAdvice;

            let _musicVo = {...data.musicVo};

            let _arr = []
            for(let i=0; i<20; i++){
               let  fangan = 'fangan' + i;
               let  gdtj = 'gdtj' + i;
               let  ltsj = 'ltsj' + i;
               let  tyfa = 'tyfa' + i;
               if(_musicVo[fangan] || _musicVo[gdtj] || _musicVo[ltsj] || _musicVo[tyfa] ){
                  _arr.push({
                     fangan:_musicVo[fangan] || "",
                     gdtj:_musicVo[gdtj] || "",
                     ltsj:_musicVo[ltsj] || "",
                     tyfa:_musicVo[tyfa] || "",
                  });
               };
            };

            this.musicVo = _arr;

         },

         handleData2(data){
            let wargData = data.constitutionResult.find(item => item.constitutionQuestionType == 3);
            let conclusion = wargData.conclusion;
            if(!!conclusion){
               let c;
               if (this.isBase64(conclusion)) {
                  c = this.base64ToString(conclusion)
               } else {
                  c = conclusion
               }
               if(this.isJSON(c)){

                  let _data = JSON.parse(c);
                  this.fromData = _data;
                  this.fromData.movements = _data.movements.split('招式').join('<br />招式');

               };
               this.fromData.createTime = data.createTime;
            }

         },

         isBase64(str) {
            try {
               btoa(atob(str)) === str;
               return true
            } catch (err) {
               return false;
            }
         },

         base64ToString(base64) {
            return decodeURIComponent(atob(base64).split('').map(function (c) {
               return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
         },

         isJSON(str) {
            if (typeof str == 'string') {
               try {
                  JSON.parse(str);
                  return true;
               } catch (e) {
                  return false;
               }
            }
         },
			
			showImg(){
				let _img = location.origin+'/imagesReport/yinyun.png';
				if(_img){
					ImagePreview([_img]);
				}
			}

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
   .yy-img{
		width: 80px;margin-left:4px;
		img{width: 100%;}
	}
</style>
