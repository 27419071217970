<template>         
	<div class="group-module" :id="id">
		<column-title  tostText="舌诊、面诊和脉诊的参数分析量化了四诊的结果，四诊合参使检测结果更加全面和详尽。通过四诊参数，您能够直观地了解各项数值指标的正常与否，从而全面掌握自己的身体状况。">
			四诊参数
			<template #tost>您可以直观地获取全面、详尽的各项数值指标</template>
			<template #right>查看说明</template>
		</column-title>
		<div style="margin-bottom: 10px;">
			<slot></slot>			
		</div>
	</div>
</template>

<script>
   import { mapState } from 'vuex';
   import columnTitle from '../components/columnTitle.vue';

   export default {
      name: 'tzjg',
		zhName:"四诊参数",
      components: {
         columnTitle
      },
		props:{
			id:{}
		},
      data() {
         return {
            
         }
      },
      computed:{
         
      },
      watch: {
        
      },
      created() {

      },
      methods: {

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";



</style>
