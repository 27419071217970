<template>
	<div  class="group-module pad-15">
		<column-nav>脉诊结果</column-nav>
		<div class="maitu">
			<div v-for="(item,index) in itemTypeArr" :key="index">
				<echart-maitu :item-type="item.itemType" height="80px" :name="item.name"></echart-maitu>
			</div>
		</div>
		<div class="module-result">
			<p>结论：{{mzResultInfo}}</p>
			<!-- <p>说明：{{mzResultInfo}} </p> -->
		</div>
		<div style="margin-top: 15px;">
			<pulse-result></pulse-result>
		</div>
	</div>
</template>

<script>
   import { mapState } from 'vuex';
	import columnNav from '../components/columnNav.vue';
   import pulseResult from '../table_module/pulse_result_2.vue';
   import echartMaitu from '../echart_module/echart_maitu.vue';

   export default {
      zhName:'面诊结果、脉诊结果',
      name: 'analysisTongue',
      components: {
         columnNav,echartMaitu,pulseResult
      },
      props:{

      },
      data() {
         return {
            fromData:{
               analysisImage:"",
					mzResultInfo:""
					
            },
            mzResultInfo:"",
            itemTypeArr:[]
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            reportType: state => {
               return state.useReportStore.reportType
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
            let faceAnalysisVo = data.faceAnalysisVo;
            this.fromData = faceAnalysisVo;
            this.mzResultInfo = data.mzResultInfo;
            this.handleMaitu();
         },

         handleMaitu(type){
            let _itemTypeArr = [
               { name:"左寸" , itemType:"left_cun" },
               { name:"右寸" , itemType:"right_cun" },
               { name:"左关" , itemType:"left_guan" },
               { name:"右关" , itemType:"right_guan" },
               { name:"左尺" , itemType:"left_chi" },
               { name:"右尺" , itemType:"right_chi" }
            ];
            this.itemTypeArr = _itemTypeArr;
         },

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
   .tongue{ }
   .module-result{}

   .maitu{
      display:grid;
      grid-template-columns:repeat(2, 50%);
      grid-template-rows:repeat(3, 85px);
   }
   .maitu > div{margin:4px;background: #f9f9f9;border-radius: 4px;}


</style>
