<template>
   <div>
      <div :id="echartId" :style="{width:width,height:height}"></div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import echarts from 'echarts';
   export default {
      zhName: '脏腑状态评估2',
      name: 'echart_viscera',
      props: {
         width:{
            default:"100%"
         },
         height:{
            default:"230px"
         },
			arrColor:{
				default:[]
			}
      },
      data() {
         return {
            echartId:Math.random().toString(36).slice(-10)
         }
      },
      computed: {
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            }
         })
      },
      watch: {
         reportData() {
            this.initChart()
         },
			echartData(data){
				
			}
      },
      methods: {

         initChart() {
            // let data = [
            //    { value: 18, name: '兼心肾不交证' , color:"#f9c041" },
            //    { value: 22, name: '兼肝阳上亢证' , color:"#07b2ed" },
            //    { value: 19, name: '肾阴阳两虚证' , color:"#88d311" },
            //    { value: 30, name: '肾阴虚证' , color:"#0561c6" },
            //    { value: 8, name: '肾阳虚症' , color:"#f96841" },
            // ];

            let echartData = this.$store.getters.reportData.esbmAnalysisPieList;
            let _arrColor = this.arrColor;
            if(!echartData|| !echartData.length){
               return
            }
            
            let data = echartData.map((item,i)=>{
               return {
                  value:item.value,
                  name:item.visceraDialecticalResult,
                  color:_arrColor[i]
               }
            });

            var option = {
               series: [
                 {
                    name: 'Nightingale Chart',
                    type: 'pie',
                    radius: ['15%', '90%'],
                    roseType: 'area',
                    data:data,
                    itemStyle:{
                        color: function(params) {
                           return params.data.color
                        },
                    },
                    label: {
                       show: true,
                       position: 'inside',
                       formatter: '{d}%'
                    }
                 }
               ]
            };

            var myChart = echarts.init(document.getElementById(this.echartId));
            myChart.setOption(option);

         }

      },
      mounted(){
         this.initChart()
      },
      created() {

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";



</style>
