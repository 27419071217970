<template>
   <div>
      <div :id="echartId" :style="{width:width,height:height}"></div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';
   import echarts from 'echarts';
   export default {
      zhName: '问诊',
      name: 'echart_wenzhen',
      props: {
         width: {
            default: "100%"
         },
         height: {
            default: "60px"
         },
         theme:{
            default:"1"
         }
      },
      data() {
         return {
            echartId: Math.random().toString(36).slice(-10),

         }
      },
      computed: {
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            }
         })
      },
      watch: {
         reportData(n, o) {
            this.handleData(n)
         }
      },
      methods: {

         handleData(data) {
            let tzbsData = data.constitutionResult.find(item =>[1,2].includes(item.constitutionQuestionType) );
            let constitution = tzbsData.constitution;
            let type = tzbsData.constitutionQuestionType;
            let dataArray = [
               tzbsData.pingheNum,
               tzbsData.yangxuNum,
               tzbsData.yinxuNum,
               tzbsData.qixuNum,
               tzbsData.tanshiNum,
               tzbsData.shireNum,
               tzbsData.xueyuNum,
               tzbsData.tebingNum,
               tzbsData.qiyuNum
            ];

            let xAxisData = [ "平和",  "阳虚", "阴虚", "气虚", "痰湿", "湿热", "血瘀", "特禀质", "气郁"];
            let colorArray = [];
            if(type == 1) {
                if(constitution=='平和质'){
                    colorArray = dataArray.map(function (item) {
                        if (item >= 60) {
                           return '#00A197'
                        } else if (item >= 40 && item < 60) {
                           return '#F79A1B'
                        } else {
                           return '#E33A3D'
                        }
                    });

                }else{
                    colorArray = dataArray.map(function (item) {
                        if (item >= 40) {
                           return '#E33A3D'
                        } else if (item >= 30 && item < 40) {
                           return '#F79A1B';
                        } else {
                           return '#00A197'
                        }
                    });
                }

            }else{
                if(constitution=='平和质') {
                    colorArray = dataArray.map(function (item) {
                        if (item >= 60) {
                           return '#00A197'
                        } else if (item >= 43.75 && item < 60) {
                           return '#F79A1B';
                        } else {
                           return '#E33A3D'
                        }
                    });
                }else{
                    colorArray = dataArray.map(function (item) {
                        if (item >= 43.75) {
                            return '#E33A3D'
                        } else if (item >= 31.25 && item < 43.75) {
                            return '#F79A1B';
                        } else {
                            return '#00A197'
                        }
                    });

                }
            }

            this.initChart(dataArray,xAxisData,colorArray)

         },

         initChart(dataArray,xAxisData,colorArray) {
            console.log(dataArray)

            var option = {
               grid:{
                  left:0,
                  right:0,
                  top:20,
                  bottom:30
               },
               xAxis: {
                  type: "category",
                  data: xAxisData,
                  axisLabel: {
                     color: "#333",
							fontSize:8
                  },
                  axisTick: {
                     show: false,
                  },
                  axisLine:{
                     show:false,
                  }
               },
               yAxis: {
						max: 100,
						show:false
               },

               series: [{
                  type: "bar",
                  data: dataArray,
                  itemStyle: {
                     normal: {
                        color: function(params) {
                           return colorArray[params.dataIndex]
                        },
                        barBorderRadius:30
                     },
                  },
                  showBackground: true,
                  backgroundStyle: {
                     color: 'rgba(180, 180, 180, 0.2)',
							barBorderRadius:30
                  },
						
                  label: {
                     show: false,
                     position: 'top',
                     color: function(params) {
                        return colorArray[params.dataIndex]
                     },
                  },
                  barWidth:12,
               }],
               backgroundColor: ""
            };


            var myChart = echarts.init(document.getElementById(this.echartId));
            myChart.setOption(option);

         }


      },
      mounted() {

         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };

      },
      created() {

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";

</style>
