<template>
   <div class="column-title">
      <div class="left-icon">
         <i></i>
      </div>
      <div class="center-info">
			<p class="title" ref="refTitle">
				<slot></slot>
			</p>
			<p class="tost">
				<slot name="tost"></slot>
			</p>         
      </div>
		<div class="right-text" @click="showPopup">
		   <i class="icon-wenhao" v-if="showIcon"></i><slot name="right"></slot>
		</div>
   </div>
</template>

<script>
	import { Dialog } from 'vant';
   export default {
      components: {

      },
      props:{
			showIcon:{
				default:true
			},
			tostText:{}
      },
      data() {
         return {

         }
      },
      computed:{

      },
      watch: {

      },
      mounted() {

      },
      created() {

      },
      methods: {

			showPopup(){
				
				let _t = this.$refs.refTitle.textContent;				
				Dialog.alert({ 
					title: "什么是"+_t,
					message: this.tostText,
					confirmButtonColor:"#2D6FDF",
					confirmButtonText:"知道了",
					theme: 'round-button',
				});
			}

      }
   }
</script>

<style lang="less" scoped>
   .column-title{
      display: flex;margin: 12px 12px 20px;    position: relative;
      .left-icon{width:10px;
			i{display: block;width:4px;height:16px;border-radius: 10px;background: #2D6FDF;margin-top:7px;}
		}
      .center-info{
			flex: 1;
			.title{font-size:20px;color:#484950;}
			.tost{font-size: 12px;color:#8F8F8F;margin-top: 5px;}
		}
		.right-text{width: auto;font-size: 12px;color:#A7A7A7;padding-top:4px;position: absolute;right:2px;}
   }
	
	.icon-wenhao{font-size:16px;margin-right:3px;float:left;margin-top: 1px;}

</style>
