<template>
		<div class="group-module" :id="id">
			<column-title tostText="根据脏腑的生理功能和病理特点，结合四诊采集的结果，我们可以辨别脏腑的病位及其阴阳、气血、虚实、寒热等变化，为治疗提供科学依据。通过脏腑辨证，您可以清晰了解自身脏腑的状态。">
				脏腑辨证
				<template #tost>您可以清晰地了解自身脏腑的状态，特别是那些可能存在疾病倾向的脏腑</template>
				<template #right>查看说明</template>
			</column-title>
			<div class="flex">
				<div style="flex: 1;">
					<echart-viscera   :arrColor="arrColor"></echart-viscera>
				</div>
				<div style="width: 140px;font-size: 12px;" class="flex-center">
					<div style="width: 100%;padding-right: 12px;">
						<p v-for="(item,i) in echartData" :key="i" style="display: flex;height:26px;color: #555;"> 
							<span style="display: inline-block;width: 12px;height: 12px;border-radius: 4px;margin-right: 10px;" :style="'background:'+item.color"></span>
							<span>{{item.name}}</span>
							<span class="icon-gengdu-copy"></span>
						</p>
					</div>
				</div>
			</div>
			<div class="pad-12">
				<div class="module-result">
					您的脏腑辨证结果为: {{ visceraPlan.visceraDialecticalResult }} ;
					<span v-if="visceraPlan.visceraPlan">说明: {{ visceraPlan.visceraPlan }}</span>
				</div>
			</div>
			
		</div>
</template>

<script>
   import { mapState } from 'vuex';
   import reportPage from '../components/report_page.vue';
   import columnTitle from '../components/columnTitle.vue';
   import echartViscera from '../echart_module/echart_viscera_2';
   import tongueResult from '../table_module/tongue_result_2';

   export default {
      zhName:'脏腑辨证结果',
      name: 'analysisTongue',
      components: {
         reportPage,columnTitle,echartViscera,tongueResult
      },
      props:{
      	id:{}
      },
      data() {
         return {
            visceraPlan:{
               analysisImage:""
            },
            tongueAnalysisVo:{

            },
				arrColor:["#f9c041","#07b2ed","#88d311","#0561c6","#f96841"],
				echartData:[],
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            },
            reportType: state => {
               return state.useReportStore.reportType
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData(n)
         }
      },
      created() {

      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(data){
            let tongueAnalysisVo = {...data.tongueAnalysisVo};
            this.tongueAnalysisVo = tongueAnalysisVo;

            let visceraPlan = {...data.visceraPlan};
            this.visceraPlan = visceraPlan;
				
				
				let echartData = this.$store.getters.reportData.esbmAnalysisPieList;
				if(!echartData|| !echartData.length){
				   return
				}
				
				let _echartData = echartData.map((item,i)=>{
				   return {
				      value:item.value,
				      name:item.visceraDialecticalResult,
				      color:this.arrColor[i]
				   }
				});
				
				this.echartData = _echartData
				
         }

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
   .module-result{text-align: center;}
	.icon-gengdu-copy{font-size: 14px;margin-left: auto;}
</style>
