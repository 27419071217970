<template>
   <table  class="report-table theme-1" border="0" width="100%" style="position: relative;top:-1px;font-size: 12px;">
      <!-- <tr>
         <td width="20%" class="table-label2"></td>
         <td class="table-label2">结果</td>
         <td class="table-label2">说明</td>
      </tr> -->
      <tr>
         <td>脉位</td>
         <td>{{ fromData.maiwei.maiweiResult }}</td>
         <td>{{ fromData.maiwei.maiweiResultInfo }}</td>
      </tr>
      <tr>
         <td>脉率</td>
         <td>{{ fromData.mailv.mailvResult }}</td>
         <td>{{ fromData.mailv.mailvResultInfo }}</td>
      </tr>
      <tr>
         <td>节律</td>
         <td>{{ fromData.jielv.jielvResult }}</td>
         <td>{{ fromData.jielv.jielvResultInfo }}</td>
      </tr>
      <tr>
         <td>脉力</td>
         <td>{{ fromData.maili.mailiResult }}</td>
         <td>{{ fromData.maili.mailiResultInfo }}</td>
      </tr>
      <tr>
         <td>紧张度</td>
         <td>{{ fromData.jinzhangdu.jinzhangduResult }}</td>
         <td>{{ fromData.jinzhangdu.jinzhangduResultInfo }}</td>
      </tr>
      <tr>
         <td>流利度</td>
         <td>{{ fromData.liulidu.liuliduResult }}</td>
         <td>{{ fromData.liulidu.liuliduResultInfo }}</td>
      </tr>
   </table>
</template>

<script>
   import { mapState } from 'vuex';
   export default {
      zhName:'脉象测定结果',
      name: 'result_pulse',
      props:{
         analysisType:{

         }
      },
      data() {
         return {
            fromData:{
               maiwei:{},
               mailv:{},
               jielv:{},
               maili:{},
               jinzhangdu:{},
               liulidu:{},
            },
            version:""
         }
      },
      computed:{
         ...mapState({
            reportData: state => {
               return state.useReportStore.reportData
            }
         })
      },
      watch:{
         reportData(n,o){
            this.handleData()
         }
      },
      created() {
         this.version = this.$store.getters.version;
      },
      mounted() {
         let n = this.$store.getters.reportData;
         if(!!n){
            this.handleData(n)
         };
      },
      methods: {

         handleData(){

            let data = this.$store.getters.reportData;
            let esbmAnalysisList = JSON.parse(JSON.stringify(data.esbmAnalysisList)) ;

            let _esbmAnalysisList = esbmAnalysisList.filter(item=>{
               let _arr = item.itemType.split("_");
               if(_arr.length<3 ){
                  return item;
               }
            });

            let _maiweiNumber = Number(_esbmAnalysisList[0].maiweiConfig.split("-")[0]) + (Number(_esbmAnalysisList[0].maiweiConfig.split("-")[1]) - Number(_esbmAnalysisList[0].maiweiConfig.split("-")[0]))/2;
            let _mailvNumber = Number(_esbmAnalysisList[0].mailvConfig.split("-")[0]) + (Number(_esbmAnalysisList[0].mailvConfig.split("-")[1]) - Number(_esbmAnalysisList[0].mailvConfig.split("-")[0]))/2;
            let _jielvNumber = Number(_esbmAnalysisList[0].jielvConfig.split("-")[0]) + (Number(_esbmAnalysisList[0].jielvConfig.split("-")[1]) - Number(_esbmAnalysisList[0].jielvConfig.split("-")[0]))/2;
            let _mailiNumber = Number(_esbmAnalysisList[0].mailiConfig.split("-")[0]) + (Number(_esbmAnalysisList[0].mailiConfig.split("-")[1]) - Number(_esbmAnalysisList[0].mailiConfig.split("-")[0]))/2;
            let _jinzhangduNumber = Number(_esbmAnalysisList[0].jinzhangduConfig.split("<")[1]);
            let _liuliduNumber = Number(_esbmAnalysisList[0].liuliduConfig.split("<")[1]);
				
            let _maiweiConfig = {..._esbmAnalysisList[0],val:0},
                _mailvConfig = {..._esbmAnalysisList[0],val:0},
                _jielvConfig = {..._esbmAnalysisList[0],val:0},
                _mailiConfig = {..._esbmAnalysisList[0],val:0},
                _jinzhangduConfig = {..._esbmAnalysisList[0],val:0},
                _liuliduConfig = {..._esbmAnalysisList[0],val:0};
					 
            _esbmAnalysisList.forEach(item=>{
               if( Math.abs(_maiweiNumber - item.maiwei) > _maiweiConfig.val ){
                  _maiweiConfig = {...item,val:Math.abs(_maiweiNumber - item.maiwei)}
               }
               if( Math.abs(_mailvNumber - item.mailv) > _mailvConfig.val ){
                  _mailvConfig = {...item,val:Math.abs(_mailvNumber - item.mailv)}
               }
               if( Math.abs(_jielvNumber - item.jielv) > _jielvConfig.val ){
                  _jielvConfig = {...item,val:Math.abs(_jielvConfig - item.jielv)}
               }
               if( Math.abs(_mailiNumber - item.maili) > _mailiConfig.val ){
                  _mailiConfig = {...item,val:Math.abs(_mailiNumber - item.maili)}
               }
               if( Math.abs(_jinzhangduNumber - item.jinzhangdu) > _jinzhangduConfig.val ){
                  _jinzhangduConfig = {...item,val:Math.abs(_jinzhangduNumber - item.jinzhangdu)}
               }
               if( Math.abs(_liuliduNumber - item.liulidu) > _liuliduConfig.val ){
                  _liuliduConfig = {...item,val:Math.abs(_liuliduNumber - item.liulidu)}
               }

            });

            this.fromData = {
               maiwei:_maiweiConfig,
               mailv:_mailvConfig,
               jielv:_jielvConfig,
               maili:_mailiConfig,
               jinzhangdu:_jinzhangduConfig,
               liulidu:_liuliduConfig
            }
            console.log(this.fromData)


         }

      }
   }
</script>

<style lang="less" scoped>
   @import "../report.less";
</style>
